/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  Paper,
  useMediaQuery,
  Card,
  CardContent
} from '@mui/material';
import {
  InputAutocomplete,
  InputRadio,
  InputRadioGroup,
  InputDate,
  InputText
} from '@aldridge/aldg-data-components';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/material/styles';
import { faSave, faTimes } from '@fortawesome/pro-regular-svg-icons';

import { doc, collection, query, onSnapshot, setDoc } from 'firebase/firestore';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { firestore, firebaseConfig } from '../../firebase';
import { UserContext } from '../../providers/UserProvider';

const _Stub = (props) => {
  const { history, match } = props;
  const user = useContext(UserContext);
  const [Saving, setSaving] = useState(false);
  const [Record, setRecord] = useState({
    id: '',
    CreatedBy: '',
    CreatedDate: '',
    ModifiedBy: '',
    ModifiedDate: '',
    JobNumber: '',
    data: {}
  });
  const mounted = useRef(null);
  const theme = useTheme();

  const STUBPAGE = '_Stub';
  const STUBCOLLECTION = 'COLLECTION';

  useEffect(() => {
    mounted.current = true;
    if (match.params.id === 'new') {
      return () => null;
    }
    const fRef = query(
      doc(collection(firestore, STUBCOLLECTION), match.params.id)
    );
    onSnapshot(fRef, (snap) => {
      if (snap.exists() && mounted) {
        setRecord(snap.data());
      }
    });
    return () => (mounted.current = false);
  }, [match.params.id]);

  const onChange = (event, name, displayFromTypeahead, autoRecord) => {
    try {
      const nameCanSplit = (n) => {
        if (n.split('.').length > 1) return [true, n.split('.')];
        return [false, n];
      };
      const [canSplit, id] =
        typeof name !== 'undefined'
          ? nameCanSplit(name)
          : nameCanSplit(event.target.name);
      const value = typeof name !== 'undefined' ? event : event.target.value;

      const changedRecord = { ...Record };
      if (canSplit) {
        changedRecord.data[id[0]][id[1]] = value;
      } else {
        changedRecord.data[id] = value;
      }
      if (typeof displayFromTypeahead !== 'undefined') {
        if (canSplit) {
          changedRecord.data[id[0]][`${id[1]}Display`] = displayFromTypeahead;
        } else {
          changedRecord.data[`${id}Display`] = displayFromTypeahead;
        }
      }

      if (changedRecord.CreatedDate === '') {
        changedRecord.CreatedBy = user.email;
        changedRecord.CreatedDate = new Date().toJSON();
      }
      changedRecord.ModifiedBy = user.email;
      changedRecord.ModifiedDate = new Date().toJSON();
      setRecord(changedRecord);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const addNew = () => {
    try {
      const savedRecord = JSON.parse(JSON.stringify(Record));
      if (match.params.id === 'new') {
        const RECORDID = doc(collection(firestore, STUBCOLLECTION));
        savedRecord.id = RECORDID.id;
      }

      const dRef = doc(collection(firestore, STUBCOLLECTION), savedRecord.id);
      // setDoc(dRef, savedRecord, { merge: true });
      history.push(`/${STUBPAGE}`);
      toast.success('Form saved successfully!', { autoClose: 5000 });
    } catch (err) {
      toast.error('Error occured while saving form.', { autoCloseq: 5000 });
    }
    history.push(`/${STUBPAGE}`);
  };

  const goBack = () => {
    history.push(`/${STUBPAGE}`);
  };

  const handleSave = () => {
    setSaving(true);
    try {
      if (match.params.id === 'new') {
        const docRef = doc(collection(firestore, STUBCOLLECTION));
        Record.id = docRef.id;
        // setDoc(docRef, Record, { merge: true }).catch((err) => {
        //   toast.error(err);
        // });
        history.push(`/${STUBPAGE}/${Record.id}`);
        setSaving(false);
      } else {
        const uRecord = { ...Record };
        uRecord.ModifiedBy = user.email;
        uRecord.ModifiedDate = new Date().toJSON();
        const docRef = doc(firestore, STUBCOLLECTION, uRecord.id);
        // setDoc(docRef, uRecord, { merge: true }).catch((err) => {
        //   toast.error(err);
        // });
        history.push(`/${STUBPAGE}`);
        setSaving(false);
      }
      toast.success(`${STUBPAGE} saved.`);
    } catch (err) {
      setSaving(false);
      toast.error(err.toString());
    }
  };
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Paper variant='outlined' className='aldg-formButtons'>
            <ToolTipIconButton
              color='success'
              onClick={handleSave}
              icon={faSave}
            />
            <ToolTipIconButton color='error' onClick={goBack} icon={faTimes} />
          </Paper>
          <Grid sx={{ padding: '3px 0' }} />
          <Card variant='outlined' className='aldg-formContainer'>
            <CardContent>
              {/* The Paper Wrapper provides useful card stylings for multiple sections. */}
              {/* <Paper variant='outlined' className='aldg-field'> */}
              <Grid container className='aldg-rootFormBox'>
                <Grid item xs={12}>
                  <h2>{STUBPAGE}</h2>
                </Grid>
              </Grid>
              {/* </Paper> */}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

_Stub.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired
};
_Stub.defaultProps = {};

export default _Stub;
