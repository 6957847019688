import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Table, TableCell, TableContainer, TableBody, TableHead, TableRow } from '@mui/material';

const SuccessStats = (props) => {
  const { successRecord, date, Type, exportHistorySuccessFunctionCall } = props;

  if (successRecord.length === 0 || date === '') return null;
  return (
    <Grid container>
      <Grid item xs={3} />
      <Grid item xs={6}>
        <TableContainer style={{ marginBottom: '16px' }} >
          <Table >
            <TableHead >
              <TableRow style={{ backgroundColor: 'white' }} className="aldg-tableHeader">
                <TableCell className="aldg-tableHeader">Company</TableCell>
                <TableCell className="aldg-tableHeader">{Type === 'Invoices' ? 'Number of Invoices' : 'Number of POs'}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(typeof successRecord === 'undefined' || successRecord.length === 0) ? null :
                successRecord.map(successes => (
                  <TableRow style={{ cursor: 'pointer' }} key={successes.Company} className="aldg-tableRow" onClick={() => exportHistorySuccessFunctionCall({ path: Type, Date: date, Company: successes.Company })}>
                    <TableCell >{successes.Company}</TableCell>
                    <TableCell >{successes[Type === 'Invoices' ? 'Number of Invoices' : 'Number of POs']}</TableCell>
                  </TableRow>
                ))}

            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={3} />
    </Grid>
  );
};

SuccessStats.propTypes = {
  successRecord: PropTypes.arrayOf(PropTypes.any).isRequired,
  date: PropTypes.string.isRequired,
  Type: PropTypes.string,
  exportHistorySuccessFunctionCall: PropTypes.func
};

SuccessStats.defaultProps = {
  Type: 'Invoices',
  exportHistorySuccessFunctionCall: () => { }
};

export default SuccessStats;