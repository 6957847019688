import React, { useState, useEffect } from 'react';
import { CircularProgress, Grid, Paper } from '@mui/material';
import { InputDate } from '@aldridge/aldg-data-components';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { isUndefined, useFunctionCall } from '@aldridge/aldg-helpers';
import { toast } from 'react-toastify';

const MonthlyARAging = () => {
  const [Record, setRecord] = useState({
    CutOffDate: ''
  });
  const onChange = (event, name) => {
    const id = typeof name !== 'undefined' ? name : event.target.name;
    const value = typeof name !== 'undefined' ? event : event.target.value;
    const changedRecord = { ...Record };
    changedRecord[id] = value;
    setRecord(changedRecord);
  };

  const { data, error, loading, functionCall } =
    useFunctionCall('MonthlyARAging');

  useEffect(() => {
    if (!isUndefined(data) && !loading) {
      toast.success(`AR Aging for ${Record.CutOffDate} Processed.`);
    }
    if (!isUndefined(error) && !loading) {
      toast.error(`An Error has occured: ${error}`);
    }
  }, [data, error, loading]);

  const GenerateFile = () => {
    if (Record.CutOffDate === '') {
      toast.error('Please select a Cut Off Date.');
      return;
    }

    functionCall();
  };
  return (
    <Grid>
      <Paper variant='outlined'>
        <Grid container justifyContent='center' sx={{ p: 1 }}>
          <Grid
            item
            xs={5}
            sx={{
              '& .MuiFormControl-root': {
                paddingBottom: '8px'
              }
            }}
          >
            <InputDate
              label='Month End Date'
              name='CutOffDate'
              value={Record.CutOffDate}
              onChange={onChange}
              style={{ paddingBottom: '5px' }}
            />
            <Grid container direction='row' justifyContent='center'>
              {!loading ? (
                <ToolTipIconButton
                  label='AR Aging By Month End'
                  icon={faCheck}
                  onClick={() => GenerateFile('Excel')}
                />
              ) : (
                <CircularProgress />
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} />
        </Grid>
      </Paper>
    </Grid>
  );
};

export default MonthlyARAging;
