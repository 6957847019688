/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-unused-vars */

import React, { useEffect, useContext, useState, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { Alert, Grid, Button, Paper } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/pro-regular-svg-icons';
import { useFunctionCall } from '@aldridge/aldg-helpers';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import * as CSV2JSON from 'csvtojson';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { auth, functions } from '../../firebase';
import { _HeaderTitle } from '../../_Recoil/atoms';
import { UserContext } from '../../providers/UserProvider';

const PDFIterator = (invoicePDF, u) => {
  const fileUpBtn = document.createElement('input');
  fileUpBtn.type = 'file';
  fileUpBtn.name = 'invoiceUpload';
  fileUpBtn.id = 'invoiceUpload';
  fileUpBtn.style = 'display:none;';
  const p = [];

  fileUpBtn.addEventListener('change', (e) => {
    const [file] = e.target.files;
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      const str = event.target.result;
      const useTabSeperator = str.indexOf('\t') > -1;
      CSV2JSON({ noheader: false, delimiter: 'auto' })
        .fromString(str)
        .then((a) => {
          const trimmed = [...new Set(a.map((r) => r['Invoice ID']))].filter(
            (r) => r !== undefined && r !== null && r !== ''
          );
          console.log(trimmed);
          invoicePDF({ csvData: trimmed, user: u.email });
          document.body.removeChild(fileUpBtn);
          console.log(a);
        });
      // const headers = str
      //   .slice(0, str.indexOf('\n'))
      //   .replace(/\t/g, ',')
      //   .split(',');
      // const rows = str
      //   .slice(str.indexOf('\n') + 1)
      //   .replace(/\t/g, ',')
      //   .split('\n')
      //   .filter((r) => r?.trim() !== '');
      // const arr = rows.map((row) => {
      //   const values = row.split(',');
      //   const el = headers.reduce((object, header, index) => {
      //     object[header.trim()] = values[index]?.trim();
      //     return object;
      //   }, {});
      //   return el;
      // });

      // console.log(arr);
      // console.log(rows);
      // const trimmed = [...new Set(arr.map((r) => r['Invoice ID']))].filter(
      //   (r) => r !== undefined && r !== null && r !== ''
      // );
      // console.log(trimmed);
      // // invoicePDF({ csvData: trimmed, user: u.email });
      // document.body.removeChild(fileUpBtn);
    };
    fileReader.readAsText(file);
  });
  document.body.appendChild(fileUpBtn);
  fileUpBtn.click();
};

const InvoicePDFGenerator = () => {
  document.title = 'Coupa Invoice Download';

  const setHeaderTitle = useSetRecoilState(_HeaderTitle);
  const user = useContext(UserContext);
  useEffect(() => setHeaderTitle('Coupa Invoice Download'), [setHeaderTitle]);
  const [zipFile, setZipFile] = useState('');
  const AnchorRef = useRef(null);
  const { data, loading, error, functionCall } = useFunctionCall(
    'coupaPDF',
    {},
    540000
  );

  const [badInvoices, setBadInvoices] = useState([]);
  useEffect(() => {
    if (typeof data !== 'undefined' && data.zip?.length > 0) {
      setZipFile(data.zip[0]);
    }
    if (typeof data !== 'undefined' && data.issues?.length > 0) {
      setBadInvoices(data.issues);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (zipFile !== '') {
      AnchorRef.current.click();
    }
  }, [zipFile]);

  const downloadBadFile = () => {
    const csvContent = badInvoices.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'badInvoices.csv');
    link.click();
  };
  return (
    <>
      {' '}
      <Paper>
        <Alert style={{ whiteSpace: 'pre-wrap' }} severity='info'>
          Please allow a few minutes for the file to be read and uploaded.{' '}
          <br />
          The process will fail after 9 minutes of running. <br />
          If you have a large file, please break it up into smaller batches. The
          process will fail after about 400 invoices.
        </Alert>
        <Grid item xs={12}>
          <Grid
            container
            style={{ paddingBottom: '13px' }}
            justifyContent='center'
          >
            <div>
              <h1>{document.title}</h1>
            </div>
          </Grid>
          <Grid item xs={12}>
            <a href={zipFile} ref={AnchorRef} target='_blank' />
            <Alert style={{ whiteSpace: 'pre-wrap' }} severity='info'>
              The file must be in CSV Format with the column &quot;Invoice
              ID&quot; present to correctly process..
            </Alert>
            {loading ? (
              <Loader type='TailSpin' height={56} />
            ) : (
              <div style={{ margin: 0 }} title='Close Record'>
                {/* <Button onClick={tempCall} disableRipple className={iconClasses.buttonWrapper}> */}
                <ToolTipIconButton
                  icon={faFileUpload}
                  tooltipText='Invoice Upload'
                  onClick={() => {
                    PDFIterator(functionCall, user);
                  }}
                />
              </div>
            )}
            {error ? (
              <Alert style={{ whiteSpace: 'pre-wrap' }} severity='error'>
                &quot;
                {error.indexOf('deadline-exceeded') > -1
                  ? 'The Request has timed out. Please try again with a smaller batch.'
                  : error}
                &quot; Please wait and try again.
              </Alert>
            ) : null}
            {badInvoices.length > 0 ? (
              <ToolTipIconButton
                label='Download Bad Files'
                onClick={downloadBadFile}
              />
            ) : null}
            <div style={{ padding: '5px 0' }}>
              {badInvoices.map((inv) => (
                <div key={inv}>{inv}</div>
              ))}
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default InvoicePDFGenerator;
