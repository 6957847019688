/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import {
  faChartNetwork,
  faRectangleHistory
} from '@fortawesome/pro-solid-svg-icons';
import {
  faFileDownload,
  faFilePdf,
  faSync
} from '@fortawesome/pro-regular-svg-icons';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { _SidebarApplicationData } from '../_Recoil/atoms';
import MenuCard from '../_GlobalComponents/MenuCard';

const TableauPage = (props) => {
  const [sidebarAppData, setSidebarAppData] = useRecoilState(
    _SidebarApplicationData
  );
  const history = useHistory();

  useEffect(() => {
    setSidebarAppData([
      {
        href: '/Tableau/RefreshExtracts', // link to location
        icon: faSync, // icon, from font awesome
        title: 'Refresh Extracts', // label
        dock: true // true if want to appear on the bottom navigation bar on mobile
      }
    ]);
  }, []);

  const Apps = [
    {
      label: 'Refresh Extracts',
      description: 'Select a Extract and Refresh It',
      icon: faSync,
      link: '/Tableau/RefreshExtracts'
    }
  ];
  return (
    <Grid container>
      {Apps.map((app) => (
        <Grid key={app.label} item xs={12} sm={6} lg={4} xl={3}>
          <MenuCard
            label={app.label}
            icon={app.icon}
            description={app.description}
            link={app.link}
            history={history}
          />
        </Grid>
      ))}
    </Grid>
  );
};

TableauPage.propTypes = {};
TableauPage.defaultProps = {};

export default TableauPage;
