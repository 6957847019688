/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { AldgWrapper, ErrorBoundary } from '@aldridge/aldg-ui-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import SidebarData from './_GlobalComponents/Menu/SideBarData';
import Application from './Pages/Application';
import UserProvider, { UserContext } from './providers/UserProvider';
import OfflineDataProvider from './providers/OfflineDataProvider';
import {
  config,
  signUserOut as signOut,
  firestore,
  functions,
  auth
} from './firebase';
import { AldgTheme } from './theme';

import './_assets/base.scss';
import 'react-toastify/dist/ReactToastify.css';
import '@aldridge/aldg-ui-components/dist/index.css';
import { _ShowChangelogModal, _SidebarApplicationData } from './_Recoil/atoms';

const Wrapper = (props) => {
  const sidebarAppData = useRecoilValue(_SidebarApplicationData);
  const [ShowChangelogModal, setShowChangelogModal] =
    useRecoilState(_ShowChangelogModal);
  return (
    <AldgWrapper
      appTitle='Integrations'
      SidebarData={[...SidebarData, ...sidebarAppData]}
      AppVersion='1.0.0'
      signOut={signOut}
      firestore={firestore}
      functions={functions}
      UserContext={UserContext}
      ShowCLModal={() => ShowChangelogModal}
      auth={auth}
      config={config}
      SetShowChangelogModal={() => setShowChangelogModal}
    >
      <Application />
    </AldgWrapper>
  );
};

Wrapper.propTypes = {};
Wrapper.defaultProps = {};

export default Wrapper;
