import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableHead,
  TableRow
} from '@mui/material';
import Loader from 'react-loader-spinner';

const VoucherSummary = (props) => {
  const { voucherRecord, voucherLoading, date } = props;

  if (voucherLoading) return <Loader type='TailSpin' width={50} />;
  if (voucherRecord.length === 0 || date === '') return null;
  console.log(voucherRecord);
  return (
    <Grid container>
      <Grid item xs={3} />
      <Grid item xs={6}>
        <TableContainer style={{ marginBottom: '16px' }}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: 'white' }}>
                <TableCell className='aldg-tableHeader'>
                  Voucher Number
                </TableCell>
                <TableCell className='aldg-tableHeader'>Src Com</TableCell>
                <TableCell className='aldg-tableHeader'>
                  No. of Invoices
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {typeof voucherRecord === 'undefined' ||
              voucherRecord.length === 0
                ? null
                : voucherRecord.map((vouchers) => (
                    <TableRow
                      key={`${vouchers['Vendor Number']}-${vouchers['Src Com']}`}
                      className='aldg-tableRow'
                    >
                      <TableCell>{vouchers['Vendor Number']}</TableCell>
                      <TableCell>{vouchers['Src Com']}</TableCell>
                      <TableCell>{vouchers['No of Invoices']}</TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={3} />
    </Grid>
  );
};

VoucherSummary.propTypes = {
  voucherRecord: PropTypes.arrayOf(PropTypes.any).isRequired,
  date: PropTypes.string.isRequired,
  voucherLoading: PropTypes.bool.isRequired
};

export default VoucherSummary;
