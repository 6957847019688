import React, { useState, useEffect } from 'react';
import { CircularProgress, Grid, Paper } from '@mui/material';
import { InputDate, InputSelect } from '@aldridge/aldg-data-components';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { faFileExcel, faFileText } from '@fortawesome/pro-regular-svg-icons';
import {
  isUndefined,
  useFunctionCall,
  exportToExcel,
  exportToTab
} from '@aldridge/aldg-helpers';
import { toast } from 'react-toastify';

const MassMutual = () => {
  const [Record, setRecord] = useState({
    OriginalHireDate: '2016-10-01',
    BeginningCheckDate: '2022-12-05',
    EndingCheckDate: '2022-12-09',
    Company: '1'
  });
  const onChange = (event, name) => {
    const id = typeof name !== 'undefined' ? name : event.target.name;
    const value = typeof name !== 'undefined' ? event : event.target.value;
    const changedRecord = { ...Record };
    changedRecord[id] = value;
    setRecord(changedRecord);
  };

  const { data, error, loading, functionCall } = useFunctionCall('MassMutual');

  useEffect(() => {
    if (!isUndefined(data) && !loading) {
      if (data.CSV) {
        exportToExcel(
          data.recordset,
          `Company_${data.Company}_401kMassMutual_${Record.BeginningCheckDate}_${Record.EndingCheckDate}.xls`
        );
      } else {
        exportToTab(
          data.recordset,
          `Company_${data.Company}_401kMassMutual_${Record.BeginningCheckDate}_${Record.EndingCheckDate}.txt`
        );
      }
      toast.success('File Downloaded Successfully');
    }
    if (!isUndefined(error) && !loading) {
      toast.error(error);
    }
  }, [data, error, loading]);

  const GenerateFile = (type) => {
    let valid = true;
    Object.keys(Record).forEach((key) => {
      if (Record[key] === '') {
        valid = false;
      }
    });

    if (!valid) {
      toast.error('One of the Fields has not been filled out.');
      return;
    }

    functionCall({
      OriginalHireDate: Record.OriginalHireDate,
      MinCheckDate: Record.BeginningCheckDate,
      MaxCheckDate: Record.EndingCheckDate,
      Company: Record.Company,
      CSV: type === 'Excel'
    });
  };
  return (
    <Grid>
      <Paper variant='outlined'>
        <Grid container justifyContent='center' sx={{ p: 1 }}>
          <Grid
            item
            xs={5}
            sx={{
              '& .MuiFormControl-root': {
                paddingBottom: '8px'
              }
            }}
          >
            <InputDate
              label='Original Hire Date'
              name='OriginalHireDate'
              value={Record.OriginalHireDate}
              onChange={onChange}
              style={{ paddingBottom: '5px' }}
            />
            <InputDate
              label='Beginning Check Date'
              name='BeginningCheckDate'
              value={Record.BeginningCheckDate}
              onChange={onChange}
            />
            <InputDate
              label='Ending Check Date'
              name='EndingCheckDate'
              value={Record.EndingCheckDate}
              onChange={onChange}
            />
            <InputSelect
              label='Which Company'
              name='Company'
              value={Record.Company}
              onChange={onChange}
              options={[{ label: '1', value: '1' }]}
            />
            {loading ? (
              <Grid container direction='row' justifyContent='center'>
                <CircularProgress style={{ justifyContent: 'center' }} />
              </Grid>
            ) : (
              <Grid container direction='row' justifyContent='center'>
                <ToolTipIconButton
                  label='Text File'
                  icon={faFileText}
                  onClick={() => GenerateFile('Tab')}
                />
                <ToolTipIconButton
                  label='Excel File'
                  icon={faFileExcel}
                  onClick={() => GenerateFile('Excel')}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} />
        </Grid>
      </Paper>
    </Grid>
  );
};

export default MassMutual;
