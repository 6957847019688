import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

const theme = createTheme();

const iconsTheme = createTheme({
  palette: {
    neutral: {
      light: '#efefef',
      main: '#bdbdbd',
      dark: '#8d8d8d',
      contrastText: '#000000'
    }
  },
  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.aldg-selectedMenuItem': {
            backgroundColor: '#eeeeee',
            backgroundImage:
              'linear-gradient(to bottom, #eeeeee 0%, #eeeeee 100%), linear-gradient(to bottom, #eeeeee 0%, #eeeeee 100%)',
            backgroundClip: 'content-box, padding-box'
          },
          '&.aldg-selectedMenuItem .MuiListItemText-primary': {
            fontWeight: 'bold'
          }
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          '& .aldg-iconContainer': {
            margin: '0 12px',
            boxShadow:
              '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
            width: '40px',
            height: '40px',
            minWidth: '0',
            padding: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '4px',
            backgroundColor: theme.palette.grey[400],
            '&:hover': {
              cursor: 'pointer',
              backgroundColor: theme.palette.grey[600],
              boxShadow:
                '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
            },
            '&.errorContainer': {
              backgroundColor: theme.palette.error.main,
              '&:hover': {
                backgroundColor: theme.palette.error.dark
              }
            },
            '&.primaryContainer': {
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark
              }
            },
            '&.grayContainer': {
              backgroundColor: theme.palette.grey[500],
              '&:hover': {
                backgroundColor: theme.palette.grey[700]
              }
            },
            '&.successContainer': {
              backgroundColor: theme.palette.success.main,
              '&:hover': {
                backgroundColor: theme.palette.success.dark
              }
            }
          },
          '& .aldg-buttonIconWrapper': {
            backgroundColor: 'transparent',
            padding: '0px',
            minWidth: '40px',
            maxWidth: '40px',
            minHeight: '40px',
            maxHeight: '40px',
            marginRight: '5px'
          },
          '& .aldg-icon': {
            color: 'white',
            fontSize: '1.5rem'
          }
        }
      }
    }
  }
});

const basicTheme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          '&.aldg-tableHeader': {
            whiteSpace: 'pre-wrap',
            fontWeight: 'bold',
            padding: '0px 16px 8px 8px',
            textAlign: 'left'
          },
          '.aldg-tableCell': {
            whiteSpace: 'pre-wrap',
            padding: '8px',
            cursor: 'pointer',
            '&.no-pointer': {
              cursor: 'default !important'
            }
          }
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.aldg-tableRow': {
            '&:nth-of-type(even)': {
              backgroundColor: '#efefef'
            },
            '&:nth-of-type(odd)': {
              backgroundColor: '#fff'
            }
          }
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.aldg-tab': {
            textTransform: 'none',
            fontWeight: 'bold',
            color: 'black'
          }
        }
      }
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          '&.aldg-saving': {
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#efefef',
            opacity: 0.5,
            zIndex: 2500
          },
          '&.aldg-gridCell': {
            border: '1px solid gray',
            textAlign: 'left',
            fontWeight: 'bold',
            alignItems: 'center',
            padding: '2px',
            '&.red': {
              color: 'red'
            }
          },
          '&.aldg-headerTitle': {
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '18px',
            paddingBottom: '12px'
          },
          '&.aldg-subheaderTitle': {
            textAlign: 'center',
            fontSize: '15px',
            color: 'slategray',
            paddingTop: '0px'
          },
          '&.aldg-bordered': {
            outline: '1px solid black',
            padding: '12px 4px'
          },
          '&.aldg-gridCellIndent': {
            border: '1px solid gray',
            textAlign: 'left',
            fontWeight: 'bold',
            alignItems: 'center',
            padding: '2px',
            paddingLeft: '24px',
            '&.red': {
              color: 'red'
            }
          },
          '&.aldg-divider': {
            padding: '0px !important',
            margin: '32px 8px',
            outline: '1px groove black',
            width: '100%',
            height: '0px'
          }
        },
        item: {
          '&.aldg-gridItem': {
            padding: '8px 0px'
          },
          '&.aldg-gridNoPadding': {
            padding: '0px !important'
          },
          padding: '4px'
        },
        container: {
          '&.aldg-rootFormBox > .MuiGrid-item': {
            padding: '8px',
            alignSelf: 'flex-end'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.aldg-inlineButton': {
            marginRight: '8px'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.aldg-formButtons': {
            position: 'sticky',
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: 'white',
            padding: theme.spacing(1),
            display: 'flex',
            justifyContent: 'flex-end',
            zIndex: 100
          },
          '&.aldg-formContainer': {
            border: '1px solid rgba(0, 0, 0, 0.12)'
          },
          '&.aldg-field': {
            padding: '8px',
            display: 'flex',
            alignItems: 'flex-end',
            width: '100%'
          }
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-footerContainer': {
            justifyContent: 'flex-start',
            marginLeft: '-12px',
            border: 'none'
          },
          '& .MuiDataGrid-columnsContainer': {
            backgroundColor: '#fff'
          },
          '& .MuiDataGrid-row:nth-of-type(odd)': {
            backgroundColor: '#efefef'
          },
          '& .MuiDataGrid-row:nth-of-type(even)': {
            backgroundColor: '#fff'
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#fff'
          },
          '&.aldg-doubleBorder': {
            '& .MuiDataGrid-main': {
              '& .MuiDataGrid-columnHeaders': {
                borderTop: 'double black',
                borderBottom: 'double black',
                borderRadius: 0,
                minHeight: '50px !important',
                maxHeight: '50px !important',
                lineHeight: '50px !important'
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                textAlign: 'center',
                whiteSpace: 'pre-wrap !important',
                lineHeight: '16px',
                fontWeight: 'bold'
              },
              '& .MuiDataGrid-virtualScroller': {
                marginTop: '50px !important'
              }
            }
          }
        }
      }
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          '& .aldg-icon': {
            fontSize: '2rem',
            transform: 'scale(calc(5/6))'
          }
        }
      }
    },
    MuiModal: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '2100 !important',
          '& .aldg-modalPaper': {
            zIndex: 1,
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '80%',
            overflow: 'auto',
            width: '80%',
            padding: theme.spacing(1, 2),
            [theme.breakpoints.down('sm')]: {
              padding: '6px 8px',
              width: '100%'
            },
            '& .aldg-modalHeader': {
              position: 'sticky',
              top: '-1px',
              left: 0,
              right: 0,
              display: 'flex',
              justifyContent: 'space-between'
            },
            '& .aldg-closeBtn': {
              minWidth: '50px',
              backgroundColor: 'transparent',
              border: 'none',
              cursor: 'pointer',
              fontSize: '20px',
              textAlign: 'right',
              paddingRight: '12px',
              color: '#f50057'
            },
            '& .aldg-modalBody': {
              position: 'relative',
              flex: '1 1 auto',
              padding: '12px',
              paddingTop: '0px'
            }
          },
          '&.aldg-level2Modal': {
            zIndex: '2200 !important'
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: '1px solid #ccc',
          '&:not(:last-child)': {
            borderBottom: 0
          },
          '&$expanded': {
            border: '1px solid #2a56a7',
            margin: '8px 0',
            '& .MuiAccordionSummary-expandIcon': {
              color: 'white'
            }
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: '#eee',
          boxShadow: 'inset 0px -19px 4px -19px #aaa',
          color: 'inherit',
          '&$expanded': {
            maxHeight: '48px',
            minHeight: '48px',
            color: 'white',
            backgroundColor: 'rgb(59, 120, 231)',
            '& .MuiButtonBase-root': {
              color: 'white'
            },
            '& $outlined': {
              border: '2px solid white'
            }
          },
          editIcon: {
            color: 'black'
          },
          expanded: {}
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          flexDirection: 'column'
        }
      }
    }
  }
});

const nonMuiThemes = {
  blackLink: {
    color: 'black',
    textDecoration: 'none'
  },
  spacer: {
    margin: '8px'
  },
  InputToggleOuterEdit: {
    padding: '1.1px 6px',
    width: '100%'
  },
  InputToggleOuter: {
    padding: '0px',
    width: '100%'
  },
  InputToggleTextContainerWrap: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  InputToggleTextContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  InputToggleTextWrap: {
    paddingRight: '8px',
    fontWeight: 'bold'
  },
  InputToggleText: {
    whiteSpace: 'nowrap',
    paddingRight: '8px',
    fontWeight: 'bold'
  },
  InputToggleValue: {
    width: '100%',
    backgroundColor: '#dce8ff',
    borderRadius: '4px',
    padding: '2px'
  }
};

const AldgTheme = createTheme(deepmerge(iconsTheme, basicTheme));

export { theme, AldgTheme, nonMuiThemes };

export default AldgTheme;
