/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import {
  faFileInvoiceDollar,
  faPiggyBank,
  faReceipt
} from '@fortawesome/pro-solid-svg-icons';
import { useSetRecoilState } from 'recoil';
import { faChartScatter } from '@fortawesome/pro-regular-svg-icons';
import { _SidebarApplicationData } from '../_Recoil/atoms';
import MenuCard from '../_GlobalComponents/MenuCard';
import UserProvider, { UserContext } from '../providers/UserProvider';

const ApplicationDashboard = (props) => {
  const history = useHistory();
  const user = useContext(UserContext);
  const Apps = [
    {
      label: 'Purchasing',
      description: 'Coupa Invoice Processing into ECMS',
      icon: faFileInvoiceDollar,
      link: 'Purchasing',
      access: user.admin || user.coupa
    },
    {
      label: 'Accounting',
      description: 'Positive Pay, Monthly A/R Aging, WIP',
      icon: faReceipt,
      link: 'Accounting',
      access: user.admin || user.positivePay || user.wip
    },
    {
      label: 'Payroll',
      description: 'Weekly 401K Mass Mutual',
      icon: faPiggyBank,
      link: 'Payroll',
      access: user.admin || user.payroll
    },
    {
      label: 'Tableau',
      description: 'Tableau Integrations',
      icon: faChartScatter,
      link: 'Tableau',
      access: user.admin || user.tableau
    }
  ];
  const setSidebarAppData = useSetRecoilState(_SidebarApplicationData);

  useEffect(() => {
    setSidebarAppData([]);
  }, []);
  return (
    <Grid container>
      {Apps.map((app) =>
        app.access ? (
          <Grid key={app.label} item xs={12} sm={6} lg={4} xl={3}>
            <MenuCard
              label={app.label}
              icon={app.icon}
              description={app.description}
              link={app.link}
              history={history}
            />
          </Grid>
        ) : null
      )}
    </Grid>
  );
};

ApplicationDashboard.propTypes = {};
ApplicationDashboard.defaultProps = {};

export default ApplicationDashboard;
