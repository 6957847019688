import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Table, TableCell, TableContainer, TableBody, TableHead, TableRow } from '@mui/material';

const FailStats = (props) => {
  const { failRecord, date, Type, exportHistoryFailFunctionCall } = props;
  if (failRecord.length === 0 || date === '') return null;
  return (
    <Grid container>
      <Grid item xs={3} />
      <Grid item xs={6}>
        <TableContainer style={{ marginBottom: '16px' }}>
          <Table>
            <TableHead >
              <TableRow key='f' className="aldg-tableHeader">
                <TableCell className="aldg-tableHeader">Error</TableCell>
                <TableCell className="aldg-tableHeader">PO Number</TableCell>
                {Type === 'Invoices' ? <TableCell className="aldg-tableHeader">Voucher</TableCell> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {(typeof failRecord === 'undefined' || failRecord.length === 0) ? <></> :
                failRecord.map(fails => (
                  <TableRow style={{ cursor: 'pointer' }} key={fails.Voucher} className="aldg-tableRow" onClick={() => exportHistoryFailFunctionCall({ path: Type, Date: date, Company: fails.Company })}>
                    <TableCell >{fails.Error}</TableCell>
                    <TableCell >{fails['PO Number']}</TableCell>
                    {Type === 'Invoices' ? <TableCell >{fails.Voucher}</TableCell> : null}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={3} />
    </Grid>
  );
};

FailStats.propTypes = {
  failRecord: PropTypes.arrayOf(PropTypes.any).isRequired,
  date: PropTypes.string.isRequired,
  Type: PropTypes.string,
  exportHistoryFailFunctionCall: PropTypes.func
};

FailStats.defaultProps = {
  Type: 'Invoices',
  exportHistoryFailFunctionCall: () => { }
};

export default FailStats;