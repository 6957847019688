import React, { useContext } from 'react';
import { Button } from '@mui/material';
import { UserContext } from '../providers/UserProvider';
import { signUserOut as signOut } from '../firebase';

const Unauthorized = () => {
  const user = useContext(UserContext);
  return (
    <div data-testid='unauthorized'>
      <div>
        You are currently unauthorized for this application with this account (
        {user.email}). Please contact the helpdesk if you should have access.
      </div>
      <div>
        <Button
          type='button'
          onClick={() => {
            signOut();
          }}
        >
          Sign out
        </Button>
      </div>
    </div>
  );
};
export default Unauthorized;
