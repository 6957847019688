/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Alert, Grid, Paper } from '@mui/material';
import {
  InputAutocomplete,
  InputDate,
  InputSelect
} from '@aldridge/aldg-data-components';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import {
  faFileExcel,
  faFileText,
  faSync
} from '@fortawesome/pro-regular-svg-icons';
import {
  isUndefined,
  useFunctionCall,
  exportToExcel,
  exportToTab
} from '@aldridge/aldg-helpers';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { firebaseConfig } from '../../firebase';

const RefreshExtracts = () => {
  const [Record, setRecord] = useState({
    TableauDatasource: []
  });
  const onChange = (event, name) => {
    const id = typeof name !== 'undefined' ? name : event.target.name;
    const value = typeof name !== 'undefined' ? event : event.target.value;
    const changedRecord = { ...Record };
    changedRecord[id] = value;
    setRecord(changedRecord);
  };
  const { data, error, loading, functionCall } =
    useFunctionCall('RefreshDatasource');
  const refreshExtract = () => {
    console.log(Record);
    if (Record.TableauDatasource.length > 0) {
      functionCall({ DatasourceId: Record.TableauDatasource[0] });
    }
  };

  useEffect(() => {
    console.log(data, loading, error);
  }, [data, loading, error]);
  return (
    <Grid>
      <Paper variant='outlined'>
        <Grid container justifyContent='center'>
          <Grid
            item
            xs={8}
            sx={{
              '& .MuiFormControl-root': {
                paddingBottom: '8px'
              }
            }}
          >
            <InputAutocomplete
              callType='function'
              onChange={onChange}
              value={Record.TableauDatasource}
              name='TableauDatasource'
              label='Select a Tableau Datasource to Refresh'
              cloudFunction={{
                name: 'TableauDatasources',
                config: firebaseConfig,
                valueKey: 'id'
              }}
              optionDisplay={(opts) =>
                `${opts?.name} [Last Updated At: ${dayjs(
                  opts?.lastUpdated
                ).format('MMM DD, YYYY hh:mm A')}]`
              }
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <ToolTipIconButton
              icon={faSync}
              label='Refresh'
              onClick={refreshExtract}
            />
          </Grid>
          <Grid item xs={8}>
            {typeof data !== 'undefined' && (
              <Alert severity='success'>{data}</Alert>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default RefreshExtracts;
