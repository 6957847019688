export const env = {
  REACT_APP_APIKEY: 'AIzaSyAJp5KkzR2U87rj8U5-APGmxgvYkHJ_JMY',
  REACT_APP_AUTHDOMAIN: 'aldg-integrations.firebaseapp.com',
  REACT_APP_PROJECTID: 'aldg-integrations',
  REACT_APP_STORAGEBUCKET: 'aldg-integrations.appspot.com',
  REACT_APP_MESSAGINGSENDERID: '555689449280',
  REACT_APP_APPID: '1:555689449280:web:9ae24dae848f514c748ca5',
  REACT_APP_MEASUREMENTID: 'G-3BYQDPZYXG',
  DATA_GRID_LICENSE:
    '59bb5309f0eb15d7a7d12015e835690dTz01MDIxMixFPTE2OTM3NjQ2NTY3NzYsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
};
