import { faHome } from '@fortawesome/pro-regular-svg-icons';

const SidebarData =
  [
    {
      href: '/', // link to location
      icon: faHome, // icon, from font awesome
      title: 'Home', // label
      dock: true, // true if want to appear on the bottom navigation bar on mobile
    }
    // reapeat these as necessary for sidebar/dock links
  ];
export default SidebarData;