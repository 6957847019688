import React, { useState, useEffect } from 'react';
import { Grid, Paper } from '@mui/material';
import { InputDate } from '@aldridge/aldg-data-components';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { faFileExcel } from '@fortawesome/pro-regular-svg-icons';
import {
  isUndefined,
  useFunctionCall,
  exportToCSV
} from '@aldridge/aldg-helpers';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

const PositivePay = () => {
  const [Record, setRecord] = useState({
    CutOffDate: dayjs().startOf('month').format('YYYY-MM-DD')
  });
  const onChange = (event, name) => {
    const id = typeof name !== 'undefined' ? name : event.target.name;
    const value = typeof name !== 'undefined' ? event : event.target.value;
    const changedRecord = { ...Record };
    changedRecord[id] = value;
    setRecord(changedRecord);
  };

  const { data, error, loading, functionCall } = useFunctionCall('PositivePay');

  useEffect(() => {
    if (!isUndefined(data) && !loading) {
      if (data.recordset.length > 0) {
        exportToCSV(
          data.recordset,
          `PrivateBank_${Record.CutOffDate.replace(/-/g, '')}.csv`
        );
      } else {
        toast.info('There are no records to download for this cut off date.');
      }
    }
    if (!isUndefined(error) && !loading) {
      toast.error(error);
    }
  }, [data, error, loading]);

  const GenerateFile = () => {
    let valid = true;
    Object.keys(Record).forEach((key) => {
      if (Record[key] === '') {
        valid = false;
      }
    });

    if (!valid) {
      toast.error('One of the Fields has not been filled out.');
      return;
    }

    functionCall({
      CutOffDate: Record.CutOffDate,
      Integration: 'P'
    });
  };
  return (
    <Grid>
      <Paper variant='outlined'>
        <Grid container justifyContent='center' sx={{ p: 1 }}>
          <Grid
            item
            xs={5}
            sx={{
              '& .MuiFormControl-root': {
                paddingBottom: '8px'
              }
            }}
          >
            <InputDate
              label='Cut Off Date'
              name='CutOffDate'
              value={Record.CutOffDate}
              onChange={onChange}
              style={{ paddingBottom: '5px' }}
            />
            <Grid container direction='row' justifyContent='center'>
              <ToolTipIconButton
                label='Export CIBC'
                icon={faFileExcel}
                onClick={() => GenerateFile('Excel')}
                loading={loading}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} />
        </Grid>
      </Paper>
    </Grid>
  );
};

export default PositivePay;
