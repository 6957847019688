/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFunctionCall } from '@aldridge/aldg-helpers';
import {
  Button,
  Grid,
  Paper,
  Tab,
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  Tabs,
  Typography
} from '@mui/material';
import Loader from 'react-loader-spinner';
import { TabContext } from '@mui/lab';
import dayjs from 'dayjs';
import { InputSelect } from '@aldridge/aldg-data-components';

import { toast } from 'react-toastify';

import InvoiceProcessing from './Invoices';

import VoucherSummary from './components/VoucherSummary';
import SuccessStats from './components/SuccessStats';
import FailStats from './components/FailStats';

import { UserContext } from '../../../providers/UserProvider';
import TabPanel from '../../../_GlobalComponents/TabPanel';
import { auth } from '../../../firebase';
import History from './History';
import PurchaseOrdersProcessing from './PurchaseOrders';

const Processing = (props) => {
  const user = useContext(UserContext);
  const [tval, setTval] = useState(0);
  const [record, setRecord] = useState('');
  const [datesRecord, setDatesRecord] = useState([]);
  const [voucherRecord, setvoucherRecord] = useState([]);
  const [successRecord, setsuccessRecord] = useState([]);
  const [failRecord, setFailRecord] = useState([]);

  const handleTChange = (event, newtval) => {
    setTval(newtval);
  };

  // runs the process invoice on the page
  // const { data: processInvoicesData, loading: processInvoicesLoading, error: processInvoicesError, functionCall: processInvoicesFunctionCall } = useFunctionCall('processInvoices', undefined, 540000);
  // // runs a delete function to delete record
  const {
    data: deleteRunsData,
    loading: deleteRunsLoading,
    error: deleteRunsError,
    functionCall: deleteRunsFunctionCall
  } = useFunctionCall('deleteRuns');
  // // exports the success records in a csv
  const {
    data: exportHistorySuccessData,
    loading: exportHistorySuccessLoading,
    error: exportHistorySuccessError,
    functionCall: exportHistorySuccessFunctionCall
  } = useFunctionCall('exportHistorySuccess');
  // // exports the failed records in a csv
  const {
    data: exportHistoryFailData,
    loading: exportHistoryFailLoading,
    error: exportHistoryFailError,
    functionCall: exportHistoryFailFunctionCall
  } = useFunctionCall('exportHistoryFail');

  const downloadFile = (Type, DateRun, encodedUri, fileName) => {
    const anchor = document.createElement('a');
    anchor.href = encodedUri;
    anchor.download = fileName;
    anchor.style = `display: none;`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };
  const exportSuccessButtonFunction = () => {
    if (
      typeof exportHistorySuccessData !== 'undefined' &&
      !exportHistorySuccessLoading
    ) {
      const newExportArray = exportHistorySuccessData.recordset;
      const DateRun = exportHistorySuccessData.Date.replace(/:/g, '_');
      const { Company } = exportHistorySuccessData;
      const finalFile = newExportArray
        .map((item) => item.RECORDLINE)
        .join('\r\n');
      const csvContent = `data:text/csv;charset=utf-8,${finalFile}`;
      const fileName = `ExportSuccessFile_${
        typeof Company !== 'undefined' ? `Co_${Company}` : ''
      }${DateRun}.csv`;
      const encodedUri = encodeURI(csvContent);
      downloadFile('Success', DateRun, encodedUri, fileName);
      toast.success('Successfully downloaded your file!');
    }
    if (
      typeof exportHistorySuccessError !== 'undefined' &&
      !exportHistorySuccessLoading
    )
      toast.error(exportHistorySuccessError);
  };

  useEffect(exportSuccessButtonFunction, [
    exportHistorySuccessData,
    exportHistorySuccessLoading,
    exportHistorySuccessError
  ]);

  const exportFailButtonFunction = () => {
    if (
      typeof exportHistoryFailData !== 'undefined' &&
      !exportHistoryFailLoading
    ) {
      const newExportArray = exportHistoryFailData.recordset;
      const DateRun = exportHistoryFailData.Date.replace(/:/g, '_');
      const { Company } = exportHistoryFailData;
      const finalFile = newExportArray
        .map((item) => item.RECORDLINE)
        .join('\r\n');
      const csvContent = `data:text/csv;charset=utf-8,${finalFile}`;
      const fileName = `ExportFailFile_${
        typeof Company !== 'undefined' ? `Co_${Company}` : ''
      }${DateRun}.csv`;
      const encodedUri = encodeURI(csvContent);
      downloadFile('Fail', DateRun, encodedUri, fileName);
      toast.success('Successfully downloaded your file!');
    }
    if (
      typeof exportHistoryFailError !== 'undefined' &&
      !exportHistoryFailLoading
    )
      toast.error('You must have a valid end date to download the file.');
  };

  useEffect(exportFailButtonFunction, [
    exportHistoryFailData,
    exportHistoryFailLoading,
    exportHistoryFailError
  ]);

  const DeleteRunDateFunction = () => {
    if (typeof deleteRunsData !== 'undefined' && !deleteRunsLoading) {
      toast.success('Successfully deleted your run!');
    }
    if (typeof deleteRunsError !== 'undefined' && !deleteRunsLoading)
      toast.error('You must have a valid end date to download the file.');
  };

  useEffect(DeleteRunDateFunction, [
    deleteRunsData,
    deleteRunsLoading,
    deleteRunsError
  ]);

  // const deleteRunDateFunction = () => {
  //   deleteRunsFunctionCall({ path: 'NonPoBackedInvoices', date: record });
  // };

  // const processInvoicesFunction = () => {
  //   processInvoicesFunctionCall({ path: 'NonPoBackedInvoices' }
  //   );
  // };

  // useEffect(() => {
  //   getAllDatesFunctionCall({ path: 'NonPoBackedInvoices' });
  // }, [auth.currentUser]);

  // useEffect(() => {
  //   if (typeof data !== 'undefined') {
  //     setDatesRecord(data.recordset);
  //   }
  //   if (typeof voucherData !== 'undefined') {
  //     setvoucherRecord(voucherData.recordset);
  //   }
  //   if (typeof successStatsData !== 'undefined') {
  //     setsuccessRecord(successStatsData.recordset);
  //   }
  //   if (typeof failStatsData !== 'undefined') {
  //     setFailRecord(failStatsData.recordset);
  //   }
  //   if (typeof processInvoicesData !== 'undefined' && processInvoicesData.recordset?.length > 0) {
  //     setRecord(processInvoicesData.recordset[0].DATE);
  //   }
  // }, [data, voucherData, successStatsData, failStatsData, processInvoicesData]);

  // useEffect(() => {
  //   if (typeof deleteRunsData !== 'undefined' && !deleteRunsLoading) {
  //     toast.success('Successfully deleted your file!');
  //     getAllDatesFunctionCall({ path: 'NonPoBackedInvoices' });
  //     setRecord('');
  //     setDatesRecord([]);
  //     setFailRecord([]);
  //     setsuccessRecord([]);
  //     setvoucherRecord([]);
  //   }
  //   if (typeof deleteRunsError !== 'undefined') {
  //     toast.error(deleteRunsError);
  //   }
  // }, [deleteRunsData, deleteRunsLoading, deleteRunsError]);
  const ExportButtons = useCallback(
    (p) =>
      p.date === '' ? null : (
        <>
          {p.successStats.length > 0 ? (
            <Button
              variant='contained'
              style={{ margin: '20px' }}
              color='primary'
              onClick={() =>
                exportHistorySuccessFunctionCall({
                  path: p.Type,
                  Date: p.date,
                  Company: p.Company
                })
              }
            >
              Export All Success Files
            </Button>
          ) : (
            <Typography variant='p' sx={{ px: 1 }}>
              No Successful Records
            </Typography>
          )}
          {p.failStats.length > 0 ? (
            <Button
              variant='contained'
              style={{ margin: '20px' }}
              color='primary'
              onClick={() =>
                exportHistoryFailFunctionCall({
                  path: p.Type,
                  Date: p.date,
                  Company: p.Company
                })
              }
            >
              Export All Error Files
            </Button>
          ) : (
            <Typography variant='p' sx={{ px: 1 }}>
              No Error Records
            </Typography>
          )}
          {p.successStats.length > 0 || p.failStats.length > 0 ? (
            <Button
              variant='contained'
              style={{ margin: '20px' }}
              color='primary'
              onClick={() =>
                deleteRunsFunctionCall({ path: p.Type, Date: p.date })
              }
            >
              Delete Run Date{' '}
              {dayjs(p.date.split('T')[0])
                .hour(p.date.split('T')[1].split(':')[0])
                .minute(p.date.split('T')[1].split(':')[1])
                .format('MMMM DD, YYYY - hh:mm A')}
            </Button>
          ) : (
            <Typography variant='p' sx={{ px: 1 }}>
              No Records to Delete
            </Typography>
          )}
        </>
      ),
    []
  );

  return (
    <Grid>
      <Paper variant='outlined'>
        <Grid item xs={12}>
          <Tabs
            value={tval}
            textColor='primary'
            onChange={handleTChange}
            variant='scrollable'
            scrollButtons='auto'
          >
            {/* <Tab label='Purchase Orders' /> */}
            <Tab label='Invoices' />
            <Tab label='History' />
          </Tabs>
        </Grid>
      </Paper>

      <TabContext value={tval.toString()}>
        {/* <PurchaseOrdersProcessing
          ExportButtons={ExportButtons}
          tval={tval}
          exportHistorySuccessFunctionCall={exportHistorySuccessFunctionCall}
          exportHistoryFailFunctionCall={exportHistoryFailFunctionCall}
        /> */}
        <InvoiceProcessing
          ExportButtons={ExportButtons}
          tval={tval}
          exportHistorySuccessFunctionCall={exportHistorySuccessFunctionCall}
          exportHistoryFailFunctionCall={exportHistoryFailFunctionCall}
        />
        <History
          ExportButtons={ExportButtons}
          tval={tval}
          exportHistorySuccessFunctionCall={exportHistorySuccessFunctionCall}
          exportHistoryFailFunctionCall={exportHistoryFailFunctionCall}
        />
      </TabContext>
    </Grid>
  );
};

Processing.propTypes = {};
Processing.defaultProps = {};

export default Processing;
