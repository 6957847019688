import React, { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ErrorBoundary } from '@aldridge/aldg-ui-components';

import { UserContext } from '../providers/UserProvider';
import SignIn from './SignIn';
import Unauthorized from './Unauthorized';
import NoMatch from './NoMatch';
import { functions, firestore, auth } from '../firebase';
import Changelog from '../_GlobalComponents/Changelog/Changelog';
import ChangelogList from '../_GlobalComponents/Changelog/ChangelogList';
import _StubList from './_Stubs/_StubListPage';
import _Stub from './_Stubs/_Stub';
import ApplicationDashboard from './ApplicationDashboard';
import Purchasing from './Purchasing';
import InvoicePDFGenerator from './Coupa/InvoicePDFGenerator';
import Processing from './Coupa/Processing/Processing';

import Payroll from './Payroll';
import MassMutual from './Payroll/MassMutual';
import PositivePay from './Accounting/PositivePay';
import Accounting from './Accounting';
import MonthlyARAging from './Accounting/MonthlyARAging';
import TableauPage from './Tableau';
import RefreshExtracts from './Tableau/RefreshExtracts';

const Application = () => {
  const user = useContext(UserContext);

  const page = () => {
    if (!user) {
      return <SignIn />;
    }
    console.log(user);
    if (user && (user.admin || user.user)) {
      return (
        <>
          <Switch>
            <Route exact path='/' component={ApplicationDashboard} />
            <Route
              exact
              path='/Purchasing'
              component={user.admin || user.coupa ? Purchasing : NoMatch}
            />
            <Route
              exact
              path='/Purchasing/InvoiceDownload'
              component={
                user.admin || user.coupa ? InvoicePDFGenerator : NoMatch
              }
            />
            <Route
              exact
              path='/Purchasing/Processing'
              component={user.admin || user.coupa ? Processing : NoMatch}
            />
            <Route
              exact
              path='/Payroll'
              component={user.admin || user.payroll ? Payroll : NoMatch}
            />
            <Route
              exact
              path='/Payroll/MassMutual'
              component={user.admin || user.payroll ? MassMutual : NoMatch}
            />
            <Route
              exact
              path='/Tableau'
              component={user.admin || user.tableau ? TableauPage : NoMatch}
            />
            <Route
              exact
              path='/Tableau/RefreshExtracts'
              component={user.admin || user.tableau ? RefreshExtracts : NoMatch}
            />
            <Route
              exact
              path='/Accounting'
              component={user.admin || user.positivePay ? Accounting : NoMatch}
            />
            <Route
              exact
              path='/Accounting/PositivePay'
              component={user.admin || user.positivePay ? PositivePay : NoMatch}
            />
            <Route
              exact
              path='/Accounting/MonthlyARAging'
              component={
                user.admin || user.positivePay ? MonthlyARAging : NoMatch
              }
            />
            <Route exact path='/_Stub' component={_StubList} />
            <Route exact path='/_Stub/:id' component={_Stub} />
            <Route
              exact
              path='/Changelog'
              component={user.SuperAdmin ? ChangelogList : NoMatch}
            />
            <Route
              exact
              path='/Changelog/:id'
              component={user.SuperAdmin ? Changelog : NoMatch}
            />

            <Route exact>
              <NoMatch />
            </Route>
          </Switch>
        </>
      );
    }

    return <Unauthorized />;
  };

  return (
    <ErrorBoundary
      componentName='The Whole Application'
      functions={functions}
      firestore={firestore}
      auth={auth}
    >
      <div className='appBody'>{page()}</div>
    </ErrorBoundary>
  );
};
export default Application;
