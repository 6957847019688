/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import { faChartNetwork, faRectangleHistory } from '@fortawesome/pro-solid-svg-icons';
import { faFileDownload, faFilePdf } from '@fortawesome/pro-regular-svg-icons';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { _SidebarApplicationData } from '../_Recoil/atoms';
import MenuCard from '../_GlobalComponents/MenuCard';


const Purchasing = (props) => {
  const [sidebarAppData, setSidebarAppData] = useRecoilState(_SidebarApplicationData);
  const history = useHistory();

  useEffect(() => {
    setSidebarAppData([
      {
        href: '/Purchasing/InvoiceDownload', // link to location
        icon: faFilePdf, // icon, from font awesome
        title: 'PDF Invoice Download', // label
        dock: true, // true if want to appear on the bottom navigation bar on mobile
      },
      {
        href: '/Purchasing/Processing', // link to location
        icon: faRectangleHistory, // icon, from font awesome
        title: 'ECMS Processing', // label
        dock: true, // true if want to appear on the bottom navigation bar on mobile
      }
    ]);
  }, []);

  const Apps = [
    {
      label: 'Invoice Download',
      description: 'Download Invoice PDFs from Coupa using a File Upload',
      icon: faFilePdf,
      link: '/Purchasing/InvoiceDownload'
    },
    {
      label: 'ECMS Processing',
      description: 'Builds a file to import invoices from Coupa to ECMS',
      icon: faFileDownload,
      link: '/Purchasing/Processing'
    }
  ];
  return (
    <Grid container>
      {Apps.map(app => <Grid key={app.label} item xs={12} sm={6} lg={4} xl={3}>
        <MenuCard label={app.label} icon={app.icon} description={app.description} link={app.link} history={history} />
      </Grid>)}
    </Grid>
  );

};


Purchasing.propTypes = {};
Purchasing.defaultProps = {};


export default Purchasing;