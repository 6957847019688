/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Table, TableRow, TableCell, TableBody, Button, Grid } from '@mui/material';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  onSnapshot,
  query,
  collection,
  orderBy,
  deleteDoc,
  doc
} from 'firebase/firestore';
import { firestore } from '../../firebase';
import ConfirmDelete from '../ConfirmDelete';

const ChangelogList = (props) => {
  const { history } = props;
  const [reports, setReports] = useState([]);

  useEffect(() => {
    let mounted = true;
    const q = query(
      collection(firestore, 'Changelog'),
      orderBy('DeployDate', 'desc')
    );
    onSnapshot(q, (snap) => {
      const docs = [];
      if (!snap.empty) {
        snap.forEach((d) => {
          docs.push(d.data());
        });
      }
      if (mounted) setReports(docs);
    });
    return () => (mounted = false);
  }, []);

  const [deleteState, setDeleteState] = useState({
    proceedLabel: 'Delete',
    cancelLabel: 'Cancel',
    title: 'Delete Record',
    modalBody: 'Are you sure you want to remove this Record?',
    show: false,
    // eslint-disable-next-line no-use-before-define
    proceed: (approved, r) => {
      removeRecord(approved, r);
    },
    enableEscape: true
  });

  const addNew = () => {
    history.push(`/changelog/new`);
  };

  const removeRecord = async (a, r) => {
    if (a) {
      if (r.idx !== '') {
        await deleteDoc(doc(firestore, 'Changelog'), r.idx).then(() => {
          toast.success('Changelog entry removed.');
        });
      }
    }
    setDeleteState({ ...deleteState, show: false });
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '8px',
            marginTop: '8px'
          }}
        >
          <div
            className='aldg-iconContainer primaryContainer'
            style={{ margin: '0px 12px' }}
          >
            <Button
              onClick={addNew}
              disableRipple
              className='aldg-buttonIconWrapper'
            >
              <FontAwesomeIcon
                icon={faPlus}
                className='aldg-icon'
                onClick={addNew}
                title='Add Changelog'
              />
            </Button>
          </div>
        </div>
        <ConfirmDelete
          proceedLabel={deleteState.proceedLabel}
          cancelLabel={deleteState.cancelLabel}
          title={deleteState.title}
          modalBody={deleteState.modalBody}
          show={deleteState.show}
          proceed={deleteState.proceed}
          enableEscape={deleteState.enableEscape}
          record={deleteState.record}
        />
        <Table>
          <TableBody>
            <TableRow style={{ backgroundColor: 'white' }}>
              <TableCell
              >
                Version
              </TableCell>
              <TableCell
              >
                Deploy Date
              </TableCell>
            </TableRow>
            {reports.map((r) => (
              <TableRow key={`${r.id}`}>
                <TableCell
                  onClick={() => history.push(`changelog/${r.id}`)}
                >
                  {r.Version}
                </TableCell>
                <TableCell
                  onClick={() => history.push(`changelog/${r.id}`)}
                >
                  {r.DeployDate}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};

ChangelogList.propTypes = {
  // match: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any)
};
ChangelogList.defaultProps = {
  // match: {
  //   params: {}
  // },
  history: {
    push: () => { }
  }
};
export default ChangelogList;
