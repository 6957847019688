import { useState, useRef, useEffect } from 'react';
import { getApp } from 'firebase/app';
import {
  getFirestore,
  collection as fCollection,
  getDocs,
  where as fWhere,
  query
} from 'firebase/firestore';
import { toast } from 'react-toastify';

const useInitializeOffline = () => {
  const [syncs, setSyncs] = useState(new Map());
  const [initialCount, setInitialCount] = useState(0);
  const toastRefs = useRef({});
  const [toastStack, setToastStack] = useState([]);

  const firebaseApp = getApp();
  const firestore = getFirestore(firebaseApp);

  useEffect(() => {
    toast.dismiss(toastRefs.current);
    if (toastStack.length > 0)
      toastRefs.current = toast.dark(`Downloading ${toastStack.join(', ')}`, {
        autoClose: false,
        position: 'bottom-right',
        id: toastStack.join('').replace(/\w/gi, '')
      });
  }, [toastStack]);

  const addSync = (key, firestoreStatement, toastMessage) => {
    const updatedSyncs = new Map(syncs);
    updatedSyncs.set(key, {
      toast: toastMessage,
      firestoreConfig: firestoreStatement
    });
    setSyncs((prevSync) => new Map([...prevSync, ...updatedSyncs]));
  };
  const syncData = () => {
    if (!navigator.onLine) return;
    const updatedToastStack = [...toastStack];
    syncs.forEach((value, key) => {
      if (updatedToastStack.indexOf(value.toast) === -1)
        updatedToastStack.push(value.toast);
      setToastStack((prev) => [...new Set([...prev, ...updatedToastStack])]);
      const { collection, where, subcollection } = value.firestoreConfig;
      const fRef = fCollection(firestore, collection);
      let q = query(fRef);
      if (typeof where !== 'undefined') {
        where.forEach((w) => {
          q = query(q, fWhere(w[0], w[1], w[2]));
        });
      }
      getDocs(q).then((res) => {
        if (subcollection) {
          const obsPromise = [];
          res.forEach((doc) => {
            const q2 = query(
              collection(firestore, `${doc.ref.path}/${subcollection}`)
            );
            obsPromise.push(getDocs(q2));
          });
          Promise.all(obsPromise)
            .then(() => {
              toast.dismiss(toastRefs.current[key]);
            })
            .catch((err) => {
              // eslint-disable-next-line 
              console.log(err);
            });
        } else {
          toast.dismiss(toastRefs.current[key]);
        }
      });
    });
  };
  useEffect(() => {
    if (initialCount !== 0 && initialCount === syncs.size) {
      syncData();
    }
  }, [syncs]);

  return {
    syncs,
    addSync,
    syncData,
    setInitialCount
  };
};

export default useInitializeOffline;
