/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import {
  faChartNetwork,
  faRectangleHistory
} from '@fortawesome/pro-solid-svg-icons';
import {
  faChartLineUp,
  faFileDownload,
  faFilePdf,
  faPiggyBank
} from '@fortawesome/pro-regular-svg-icons';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { _SidebarApplicationData } from '../_Recoil/atoms';
import MenuCard from '../_GlobalComponents/MenuCard';

const Accounting = (props) => {
  const [sidebarAppData, setSidebarAppData] = useRecoilState(
    _SidebarApplicationData
  );
  const history = useHistory();

  useEffect(() => {
    setSidebarAppData([
      {
        href: '/Accounting/PositivePay', // link to location
        icon: faPiggyBank, // icon, from font awesome
        title: 'Positive Pay', // label
        dock: true // true if want to appear on the bottom navigation bar on mobile
      },
      {
        href: '/Accounting/MonthlyARAging', // link to location
        icon: faChartLineUp, // icon, from font awesome
        title: 'Monthly AR Aging', // label
        dock: true // true if want to appear on the bottom navigation bar on mobile
      }
    ]);
  }, []);

  const Apps = [
    {
      label: 'Positive Pay',
      description: 'Downloads Bank Records Based on Cut Off Date',
      icon: faPiggyBank,
      link: '/Accounting/PositivePay'
    },
    {
      label: 'Monthly AR Aging',
      description:
        'Processes AR Invoices By Month End for Aldridge AR Aging Invoices Datasource',
      icon: faChartLineUp,
      link: '/Accounting/MonthlyARAging'
    }
  ];
  return (
    <Grid container>
      {Apps.map((app) => (
        <Grid key={app.label} item xs={12} sm={6} lg={4} xl={3}>
          <MenuCard
            label={app.label}
            icon={app.icon}
            description={app.description}
            link={app.link}
            history={history}
          />
        </Grid>
      ))}
    </Grid>
  );
};

Accounting.propTypes = {};
Accounting.defaultProps = {};

export default Accounting;
