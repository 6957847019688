/* eslint-disable no-unused-vars */
import { RecoilRoot, useRecoilValue, useSetRecoilState } from 'recoil';

import React from 'react';
import ReactDOM from 'react-dom';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { AldgWrapper, ErrorBoundary } from '@aldridge/aldg-ui-components';
import { OfflineProvider } from '@aldridge/aldg-helpers';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import SidebarData from './_GlobalComponents/Menu/SideBarData';
import Application from './Pages/Application';
import UserProvider, { UserContext } from './providers/UserProvider';
import OfflineDataProvider from './providers/OfflineDataProvider';
import {
  config,
  signUserOut as signOut,
  firestore,
  functions,
  auth
} from './firebase';
import { AldgTheme } from './theme';

import './_assets/base.scss';
import 'react-toastify/dist/ReactToastify.css';
import '@aldridge/aldg-ui-components/dist/index.css';
import { _ShowChangelogModal } from './_Recoil/atoms';
import Wrapper from './Wrapper';

const rootElement = document.getElementById('root');

const renderApp = () => {
  try {
    ReactDOM.render(
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={AldgTheme}>
          <RecoilRoot>
            <OfflineProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <UserProvider>
                  <ErrorBoundary
                    functions={functions}
                    firestore={firestore}
                    auth={auth}
                    componentName='Main Application'
                    user={{ email: '' }}
                  >
                    <OfflineDataProvider>
                      <Wrapper />
                    </OfflineDataProvider>
                  </ErrorBoundary>
                </UserProvider>
              </LocalizationProvider>
            </OfflineProvider>
          </RecoilRoot>
        </ThemeProvider>
      </StyledEngineProvider>,
      rootElement
    );
  } catch (err) {
    // eslint-disable-next-line
    console.trace(err);
  }
};
renderApp();
