import { initializeApp, getApp } from 'firebase/app';
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  setPersistence,
  browserLocalPersistence,
  signOut
} from 'firebase/auth';
import {
  getFirestore,
  enableIndexedDbPersistence,
  clearIndexedDbPersistence,
  terminate
} from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import { toast } from 'react-toastify';
import { env } from './.env';

// config file in env
export const firebaseConfig = {
  apiKey: env.REACT_APP_APIKEY,
  authDomain: env.REACT_APP_AUTHDOMAIN,
  projectId: env.REACT_APP_PROJECTID,
  storageBucket: env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: env.REACT_APP_MESSAGINGSENDERID,
  appId: env.REACT_APP_APPID,
  measurementId: env.REACT_APP_MEASUREMENTID
};
// intializes app
export const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);

const GoogleProvider = new GoogleAuthProvider();
GoogleProvider.setCustomParameters({
  prompt: 'select_account'
});
let confirmCode = null;
let codeSent = null;

const recaptchaVerifier = new RecaptchaVerifier(
  'recaptcha-container',
  {
    size: 'invisible',
    callback: (response) => {
      // reCAPTCHA solved, allow signInWithPhoneNumber.
      codeSent = true;
      window.confirmationResult = response;
      // onSignInSubmit();
    }
  },
  auth
);

if (window.location.hostname === 'localhost') {
  connectFunctionsEmulator(getFunctions(getApp()), 'localhost', 5001);
}

export const firestore = getFirestore();

enableIndexedDbPersistence(firestore, { forceOwnership: true }).catch((err) => {
  toast.error(err.message);
});

setPersistence(auth, browserLocalPersistence).catch((err) => {
  toast.error(err.message);
});
export const functions = getFunctions(firebaseApp);
export const storage = getStorage(firebaseApp);
export const config = firebaseConfig;
export const analytics = getAnalytics(firebaseApp);
export const CONFIRM_CODE = confirmCode;
export const CODE_SENT = codeSent;

export const signUserOut = () =>
  new Promise((resolve) => {
    console.log('signing out...');
    terminate(firestore).then(() => {
      console.log('firestore terminated...');
      clearIndexedDbPersistence(firestore).then(() => {
        console.log('persistence cleared...');
        signOut(auth).then(() => {
          console.log('auth signed out...');
          resolve('signed out');
        });
      });
    });
  });
export const signInWithGoogle = async () => {
  // if ('standalone' in window.navigator) {
  //   const r = await signInWithRedirect(
  //     auth,
  //     GoogleProvider,
  //     browserPopupRedirectResolver
  //   );
  //   console.log(r);
  //   // getRedirectResult(auth, browserPopupRedirectResolver)
  //   //   .then((result) => {
  //   //     try {
  //   //       console.log(result);
  //   //       // if (result === null) {
  //   //       //   signInWithRedirect(
  //   //       //     auth,
  //   //       //     GoogleProvider,
  //   //       //     browserPopupRedirectResolver
  //   //       //   );
  //   //       // }
  //   //     } catch (err) {
  //   //       console.log(err);
  //   //       // signInWithRedirect(
  //   //       //   auth,
  //   //       //   GoogleProvider,
  //   //       //   browserPopupRedirectResolver
  //   //       // );
  //   //     }
  //   //   })
  //   //   .catch((error) => {
  //   //     // eslint-disable-next-line no-console
  //   //     console.log(error);
  //   //     toast.error(error.message);
  //   //   });
  // } else {
  signInWithPopup(auth, GoogleProvider).catch((err) => {
    // eslint-disable-next-line no-console
    console.log(err);
    toast.error(`${err.message}`);
  });
  // }
};
export const signInWithPhone = (callback) => {
  const phoneNumber = document.getElementById('userPhoneNumberLogIn').value;
  const PHONENUMBER = phoneNumber.length === 10 ? `+1${phoneNumber}` : null;
  if (PHONENUMBER === null) {
    throw new Error('Phone Number needs to be 10 characters long.');
  }
  signInWithPhoneNumber(auth, PHONENUMBER, recaptchaVerifier).then((res) => {
    callback(res);
    confirmCode = res;
  });
};
export const verifyCode = (callback) => {
  const code = document.getElementById('userVerificationCode').value;
  confirmCode.confirm(code).then((res) => {
    callback(res);
  });
};
