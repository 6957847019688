import { faMessageQuestion } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const NoMatch = () => (
  <Grid container justifyContent='center'>
    <Grid item xs={6} textAlign='center'>
      <FontAwesomeIcon icon={faMessageQuestion} size='4x' />
      <br />
      <h2>This Page does not exist.</h2>
      <h3>
        Please go back to the <Link to='/'>main screen</Link> and try a
        different page.
      </h3>
    </Grid>
  </Grid>
);

export default NoMatch;
