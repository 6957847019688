import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MenuCard = ({ label, icon, description, link }) => (
  <Card sx={{ outline: '1px solid black', margin: '8px' }} elevation={4}>
    <CardHeader
      style={{ fontSize: '40px' }}
      title={label}
      titleTypographyProps={{ style: { fontSize: '20px' } }}
      avatar={<FontAwesomeIcon icon={icon} />}
    />
    <CardContent>{description}</CardContent>
    <div style={{ margin: '8px -8px', outline: '1px solid black' }} />
    <CardContent style={{ textAlign: 'right', paddingBottom: '16px' }}>
      <Link to={link} style={{ color: 'black' }}>
        View Page
      </Link>
    </CardContent>
  </Card>);

MenuCard.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.objectOf(PropTypes.any).isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default MenuCard;