/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { faTimes, faSave } from '@fortawesome/pro-regular-svg-icons';
import { Grid, Paper, Card, CardContent } from '@mui/material';
import {
  InputText,
  InputDate,
  RepeatableList,
  InputRadio,
  AldgDataGrid
} from '@aldridge/aldg-data-components';
import { doc, onSnapshot, setDoc, collection } from 'firebase/firestore';
import { useRecoilState } from 'recoil';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { UserContext } from '../../providers/UserProvider';
import { firestore } from '../../firebase';

const Changelog = (props) => {
  const { history, match } = props;
  const user = useContext(UserContext);

  const [cl, setCL] = useState({
    id: '',
    Version: '',
    DeployDate: '',
    Deployed: 'Yes',
    Changes: []
  });

  const [promises, setPromises] = useState([]);
  const onChange = (event, name, displayFromTypeahead) => {
    try {
      const nameCanSplit = (n) => {
        if (n.split('.').length > 1) return [true, n.split('.')];
        return [false, n];
      };
      const [canSplit, id] =
        typeof name !== 'undefined'
          ? nameCanSplit(name)
          : nameCanSplit(event.target.name);
      const value = typeof name !== 'undefined' ? event : event.target.value;
      const changedRecord = { ...cl };
      if (canSplit) {
        changedRecord[id[0]][id[1]] = value;
      } else {
        changedRecord[id] = value;
      }

      if (typeof displayFromTypeahead !== 'undefined') {
        if (canSplit) {
          changedRecord[id[0]][`${id[1]}Display`] = displayFromTypeahead;
        } else {
          changedRecord[`${id}Display`] = displayFromTypeahead;
        }
      }
      setCL(changedRecord);
    } catch (err) {
      // toast.error(err.message);
    }
  };
  useEffect(() => {
    let mounted = true;
    if (match.params.id !== 'new') {
      onSnapshot(doc(firestore, 'Changelog', match.params.id), (snap) => {
        if (snap.exists && mounted) {
          setCL(snap.data());
        }
      });
    }
    return () => (mounted = false);
  }, [match]);
  const removeLineItem = (list, idx) => {
    const changedRecord = { ...cl };
    changedRecord.Changes.splice(idx, 1);
    setCL(changedRecord);
  };

  const close = () => {
    history.push(`/changelog`);
  };
  const saveReport = () => {
    try {
      if (match.params.id === 'new') {
        const updatedRec = { ...cl };
        const docRef = doc(collection(firestore, 'Changelog'));
        updatedRec.id = docRef.id;
        setDoc(docRef, updatedRec, { merge: true });
        setCL(updatedRec);
        history.push(`/Changelog`);
        return;
      }
      setDoc(doc(firestore, 'Changelog', cl.id), cl, { merge: true });
      history.push(`/Changelog`);
      toast.success('Form saved successfully!', { autoClose: 5000 });
    } catch (err) {
      toast.error('Error occured while saving form.', { autoClose: 5000 });
    }
  };

  const cols = [
    {
      field: 'Change',
      headerName: 'Change',
      canEdit: true,
      inputType: 'InputText',
      flex: 1
    },
    {
      field: 'FeatureType',
      headerName: 'Feature Type',
      canEdit: true,
      inputType: 'InputSelect',
      options: [
        { value: 'Enhancement', label: 'Enhancement' },
        { value: 'Bug Fix', label: 'Bug Fix' }
      ],
      flex: 0
    }
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper
          variant='outlined'
          className='aldg-formButtons'
          style={{ border: 'none' }}
        >
          <ToolTipIconButton
            icon={faSave}
            onClick={saveReport}
            title='Save Changelog'
            color='success'
          />
          <ToolTipIconButton
            icon={faTimes}
            onClick={close}
            title='Close Changelog'
            color='error'
          />
        </Paper>
        <Grid sx={{ padding: '3px 0' }} />
        <Card style={{ width: '100%' }}>
          <CardContent>
            <Grid container className='aldg-rootFormBox'>
              <Grid item xs={10}>
                <InputText
                  label='Version'
                  name='Version'
                  value={cl.Version}
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={2}>
                <InputRadio
                  radios={[
                    { value: 'Yes', label: 'Yes' },
                    { value: 'No', label: 'No' }
                  ]}
                  FormControlLabelProps={{
                    label: 'Has this Version Been Deployed?'
                  }}
                  RadioGroupProps={{
                    name: 'Deployed',
                    onChange,
                    value: cl.Deployed || ''
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputDate
                  label='Deploy Date'
                  name='DeployDate'
                  value={cl.DeployDate}
                  onChange={onChange}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <AldgDataGrid columns={cols} editable rows={cl.Changes} updateOverride={promises} setUpdateOverride={setPromises} />
              </Grid> */}
              <Grid item xs={12}>
                <RepeatableList
                  key={cl.id}
                  user={user}
                  listTransaction={{
                    Change: ''
                  }}
                  label='Changes'
                  transactions={cl.Changes || []}
                  canAdd
                  canDelete
                  name='Changes'
                  onChange={onChange}
                  deleteFunc={removeLineItem}
                  breakpoint='900px'
                  rowObjects={[
                    <InputText name='Change' label='Change' itemSize={11} />
                  ]}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

Changelog.propTypes = {
  match: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.objectOf(PropTypes.any)
};
Changelog.defaultProps = {
  match: {
    params: {}
  },
  history: {
    push: () => {}
  }
};
export default Changelog;
