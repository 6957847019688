import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Paper, Grid, Button } from '@mui/material';
import { useFunctionCall } from '@aldridge/aldg-helpers';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';
import SuccessStats from './components/SuccessStats';
import FailStats from './components/FailStats';

import TabPanel from '../../../_GlobalComponents/TabPanel';

const PurchaseOrdersProcessing = (props) => {
  const { ExportButtons, tval, exportHistorySuccessFunctionCall, exportHistoryFailFunctionCall } = props;

  const [Date, setDate] = useState('');
  const { data: processPurchaseOrdersData, loading: processPurchaseOrdersLoading, error: processPurchaseOrdersError, functionCall: processPurchaseOrdersFunctionCall } = useFunctionCall('processPurchaseOrders', undefined, 540000);
  const { data: successStatsData, loading: successStatsLoading, error: successStatsError, functionCall: successStatsFunctionCall } = useFunctionCall('successStats');
  const { data: failStatsData, loading: failStatsLoading, error: failStatsError, functionCall: failStatsFunctionCall } = useFunctionCall('failStats');

  useEffect(() => {
    if (!processPurchaseOrdersLoading && typeof processPurchaseOrdersData !== 'undefined') {
      toast.success('Processed Invoices');
      if (processPurchaseOrdersData.recordset?.length > 0) {
        setDate(processPurchaseOrdersData.recordset[0].DATE);
      }
    }
    if (!processPurchaseOrdersLoading && typeof processPurchaseOrdersError !== 'undefined') {
      toast.error(processPurchaseOrdersError);
    }
  }, [processPurchaseOrdersData, processPurchaseOrdersLoading, processPurchaseOrdersError]);

  useEffect(() => {
    if (Date !== '') {
      successStatsFunctionCall({
        path: 'PurchaseOrders',
        SuccessDate: Date
      });
      failStatsFunctionCall({
        path: 'PurchaseOrders',
        FailDate: Date
      });
    }
  }, [Date]);
  useEffect(() => {
    if (typeof successStatsError !== 'undefined') toast.error(successStatsError);
    if (typeof failStatsError !== 'undefined') toast.error(failStatsError);
  }, [
    successStatsError,
    failStatsError,
  ]);
  return (
    <TabPanel value={tval} index={0}>
      <Paper variant='outlined' style={{ padding: '8px' }}>
        <Grid container>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <ExportButtons date={Date} />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            {processPurchaseOrdersLoading ? <div style={{
              display: 'flex',
              position: 'fixed',
              zIndex: 2600,
              backgroundColor: '#fff',
              width: '100%',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              opacity: '.70'
            }}>
              <Loader
                type="TailSpin"
                color="#3b78e7"
                height={50}
                width={50}
              />Processing... </div> : <>
              <Button variant="contained" style={{ margin: '20px' }} color='primary' onClick={() => processPurchaseOrdersFunctionCall({ path: 'PurchaseOrders' })}>
                Process Purchase Orders</Button>

              <SuccessStats successRecord={successStatsData || []} successLoading={successStatsLoading} date={Date} Type='PurchaseOrders' exportHistorySuccessFunctionCall={exportHistorySuccessFunctionCall} />
              <FailStats failRecord={failStatsData || []} failLoading={failStatsLoading} date={Date} Type='PurchaseOrders' exportHistoryFailFunctionCall={exportHistoryFailFunctionCall} />
            </>}
          </Grid>
        </Grid>
      </Paper>
    </TabPanel>
  );
};

PurchaseOrdersProcessing.propTypes = {
  ExportButtons: PropTypes.func,
  tval: PropTypes.number,
  exportHistorySuccessFunctionCall: PropTypes.func,
  exportHistoryFailFunctionCall: PropTypes.func,
};

PurchaseOrdersProcessing.defaultProps = {
  ExportButtons: () => { },
  tval: -1,
  exportHistorySuccessFunctionCall: () => { },
  exportHistoryFailFunctionCall: () => { },
};
export default PurchaseOrdersProcessing;